import React from "react";
import f3 from "family-chart";  // npm i family-chart
import './family-chart.css';  // create file 'family-chart.css' in same directory, copy/paste css from examples/create-tree

export default class FamilyTree extends React.Component {
  cont = React.createRef();

  componentDidMount() {
    if (!this.cont.current) return;
    
    const store = f3.createStore({
        data: data(),
        node_separation: 250,
        level_separation: 150
      }),
      view = f3.d3AnimationView({
        store,
        cont: document.querySelector("#FamilyChart")
      }),
      Card = f3.elements.Card({
        store,
        svg: view.svg,
        card_dim: {w:220,h:70,text_x:75,text_y:15,img_w:60,img_h:60,img_x:5,img_y:5},
        card_display: [d => `${d.data['first name'] || ''} ${d.data['last name'] || ''}`,d => `${d.data['birthday'] || ''}`],
        mini_tree: true,
        link_break: false
      })
  
    view.setCard(Card)
    store.setOnUpdate(props => view.update(props || {}))
    store.update.tree({initial: true})
    
    function data() {
      return [
  {
    "id": "0",
    "rels": {
      "spouses": [
        "c819042f-585d-432f-911a-4529d9e565ac"
      ],
      "children": [
        "f229a6da-c495-4505-a6d6-5a3a6c3949d9"
      ]
    },
    "data": {
      "first name": "Lingayya",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg",
      "gender": "M"
    }
  },
  {
    "id": "c819042f-585d-432f-911a-4529d9e565ac",
    "data": {
      "gender": "F",
      "first name": "Wife of Lingayya",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "0"
      ],
      "children": [
        "f229a6da-c495-4505-a6d6-5a3a6c3949d9"
      ]
    }
  },
  {
    "id": "f229a6da-c495-4505-a6d6-5a3a6c3949d9",
    "data": {
      "gender": "M",
      "first name": "Venkayya ",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "0",
      "mother": "c819042f-585d-432f-911a-4529d9e565ac",
      "spouses": [
        "8458430a-7302-4604-84b4-09c2e7e44838"
      ],
      "children": [
        "620284d1-1a92-4a9c-9880-10dd931274c1"
      ]
    }
  },
  {
    "id": "8458430a-7302-4604-84b4-09c2e7e44838",
    "data": {
      "gender": "F",
      "first name": "Wife of Venkayya",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "f229a6da-c495-4505-a6d6-5a3a6c3949d9"
      ],
      "children": [
        "620284d1-1a92-4a9c-9880-10dd931274c1"
      ]
    }
  },
  {
    "id": "620284d1-1a92-4a9c-9880-10dd931274c1",
    "data": {
      "gender": "M",
      "first name": "Chenchayya ",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "8458430a-7302-4604-84b4-09c2e7e44838",
      "father": "f229a6da-c495-4505-a6d6-5a3a6c3949d9",
      "spouses": [
        "f28cb045-f40d-443a-80f7-467f4858f9e8"
      ],
      "children": [
        "0318ba16-cb3f-4126-ba14-051f3517d179"
      ]
    }
  },
  {
    "id": "f28cb045-f40d-443a-80f7-467f4858f9e8",
    "data": {
      "gender": "F",
      "first name": "Wife of Chenchayya ",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "620284d1-1a92-4a9c-9880-10dd931274c1"
      ],
      "children": [
        "0318ba16-cb3f-4126-ba14-051f3517d179"
      ]
    }
  },
  {
    "id": "0318ba16-cb3f-4126-ba14-051f3517d179",
    "data": {
      "gender": "M",
      "first name": "Nagayyah",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "620284d1-1a92-4a9c-9880-10dd931274c1",
      "mother": "f28cb045-f40d-443a-80f7-467f4858f9e8",
      "spouses": [
        "b03af160-0cb8-460d-8a9c-14ba67354ae4"
      ],
      "children": [
        "15ca1642-70b4-41c4-ad22-db05f25fe863"
      ]
    }
  },
  {
    "id": "b03af160-0cb8-460d-8a9c-14ba67354ae4",
    "data": {
      "gender": "F",
      "first name": "Wife of Nagayyah ",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "0318ba16-cb3f-4126-ba14-051f3517d179"
      ],
      "children": [
        "15ca1642-70b4-41c4-ad22-db05f25fe863"
      ]
    }
  },
  {
    "id": "15ca1642-70b4-41c4-ad22-db05f25fe863",
    "data": {
      "gender": "M",
      "first name": "Subbayyah ",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "b03af160-0cb8-460d-8a9c-14ba67354ae4",
      "father": "0318ba16-cb3f-4126-ba14-051f3517d179",
      "spouses": [
        "40bfdd11-31a2-48c6-93c2-e255f5a1436c"
      ],
      "children": [
        "45631457-c409-49a1-b684-9948bb801e0a"
      ]
    }
  },
  {
    "id": "40bfdd11-31a2-48c6-93c2-e255f5a1436c",
    "data": {
      "gender": "F",
      "first name": "Wife of Subbayyah ",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "15ca1642-70b4-41c4-ad22-db05f25fe863"
      ],
      "children": [
        "45631457-c409-49a1-b684-9948bb801e0a"
      ]
    }
  },
  {
    "id": "45631457-c409-49a1-b684-9948bb801e0a",
    "data": {
      "gender": "M",
      "first name": "Nagaiah Raja",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "40bfdd11-31a2-48c6-93c2-e255f5a1436c",
      "father": "15ca1642-70b4-41c4-ad22-db05f25fe863",
      "spouses": [
        "4c45bee2-00c7-4f41-99b7-678f2f263c9d"
      ],
      "children": [
        "bcb91948-0288-424d-9d56-ee57a975ad9f",
        "de418782-6537-40fb-8f7d-802142487634",
        "17b5dd1c-c586-4437-86a3-89a813b8e378",
        "4cd034b3-881c-4474-8d7d-db65e1bd25ce"
      ]
    }
  },
  {
    "id": "4c45bee2-00c7-4f41-99b7-678f2f263c9d",
    "data": {
      "gender": "F",
      "first name": "SeethaRamamma",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "45631457-c409-49a1-b684-9948bb801e0a",
        "e9922c32-01a2-4d8d-9bb9-68e2d824fde0"
      ],
      "children": [
        "bcb91948-0288-424d-9d56-ee57a975ad9f",
        "de418782-6537-40fb-8f7d-802142487634",
        "17b5dd1c-c586-4437-86a3-89a813b8e378",
        "4cd034b3-881c-4474-8d7d-db65e1bd25ce"
      ]
    }
  },
  {
    "id": "bcb91948-0288-424d-9d56-ee57a975ad9f",
    "data": {
      "gender": "M",
      "first name": "Janardhan Raja",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "4c45bee2-00c7-4f41-99b7-678f2f263c9d",
      "father": "45631457-c409-49a1-b684-9948bb801e0a",
      "spouses": [
        "5107ea7b-2af8-419b-8737-f6376e273087"
      ],
      "children": [
        "f553109d-83ad-48b1-a486-f6f6ef2acaa7",
        "839713d2-1cff-46c9-a6aa-786e4c672c88",
        "34c2e2a7-6900-4332-83e4-3e6d415e374b"
      ]
    }
  },
  {
    "id": "de418782-6537-40fb-8f7d-802142487634",
    "data": {
      "gender": "M",
      "first name": "BhavaNarayana Raja",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "4c45bee2-00c7-4f41-99b7-678f2f263c9d",
      "father": "45631457-c409-49a1-b684-9948bb801e0a",
      "spouses": [
        "027910cd-7a03-40c9-a614-f89aa70ef6af"
      ],
      "children": [
        "0adea439-3093-420f-b4c4-92dd1ee08f78",
        "4eb9f27a-61c0-42d9-a232-fb322ddda48b"
      ]
    }
  },
  {
    "id": "17b5dd1c-c586-4437-86a3-89a813b8e378",
    "data": {
      "gender": "F",
      "first name": "Shanta Kolli",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "4c45bee2-00c7-4f41-99b7-678f2f263c9d",
      "father": "45631457-c409-49a1-b684-9948bb801e0a",
      "spouses": [
        "f29d0e02-ba27-49f6-9325-53d59cb13830"
      ],
      "children": [
        "8ea94d41-64ac-40c7-93e9-f77626fbc0c0",
        "0e5f8ba5-f521-4d83-9fbe-00dd4f8fa726"
      ]
    }
  },
  {
    "id": "e9922c32-01a2-4d8d-9bb9-68e2d824fde0",
    "data": {
      "gender": "M",
      "first name": "Vimala",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "4c45bee2-00c7-4f41-99b7-678f2f263c9d"
      ]
    }
  },
  {
    "id": "4cd034b3-881c-4474-8d7d-db65e1bd25ce",
    "data": {
      "gender": "F",
      "first name": "Vimala",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "45631457-c409-49a1-b684-9948bb801e0a",
      "mother": "4c45bee2-00c7-4f41-99b7-678f2f263c9d",
      "spouses": [
        "b93fac1c-dd6a-42b8-893c-30ec0a3bad3f"
      ],
      "children": [
        "b48ba152-e32c-43af-84c0-87140cc9cf0b",
        "fb250762-f0da-4207-9b7c-a486112f6859",
        "8e55515c-eeaa-4247-b58d-e60b5135e6e2",
        "3f79b386-3205-4d2e-a649-22c4fb8320b6"
      ]
    }
  },
  {
    "id": "5107ea7b-2af8-419b-8737-f6376e273087",
    "data": {
      "gender": "F",
      "first name": "SeethaRatnam ",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "bcb91948-0288-424d-9d56-ee57a975ad9f"
      ],
      "children": [
        "f553109d-83ad-48b1-a486-f6f6ef2acaa7",
        "839713d2-1cff-46c9-a6aa-786e4c672c88",
        "34c2e2a7-6900-4332-83e4-3e6d415e374b"
      ],
      "father": "4b80f15b-b5ef-494c-8c67-d7fa228e56e8",
      "mother": "5c60bd36-d559-4030-bf52-126d67c65bbd"
    }
  },
  {
    "id": "f553109d-83ad-48b1-a486-f6f6ef2acaa7",
    "data": {
      "gender": "M",
      "first name": "Apparao Raja",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "bcb91948-0288-424d-9d56-ee57a975ad9f",
      "mother": "5107ea7b-2af8-419b-8737-f6376e273087",
      "spouses": [
        "d49acc6e-528e-4f19-94a5-bd68666e25d8"
      ],
      "children": [
        "b8e56ff5-affe-4997-8476-aa9f7a4d0c68",
        "afa6d5b8-2b34-4b66-b4d9-1bd858403494"
      ]
    }
  },
  {
    "id": "839713d2-1cff-46c9-a6aa-786e4c672c88",
    "data": {
      "gender": "F",
      "first name": "Ramana Surampudi",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "bcb91948-0288-424d-9d56-ee57a975ad9f",
      "mother": "5107ea7b-2af8-419b-8737-f6376e273087",
      "spouses": [
        "cdb217e4-91ae-4170-ab0c-7656504ae783"
      ],
      "children": [
        "5456f95b-f2ce-40c7-8ced-85a6ec8fdd1b"
      ]
    }
  },
  {
    "id": "34c2e2a7-6900-4332-83e4-3e6d415e374b",
    "data": {
      "gender": "M",
      "first name": "Choudhary Raja",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "bcb91948-0288-424d-9d56-ee57a975ad9f",
      "mother": "5107ea7b-2af8-419b-8737-f6376e273087",
      "spouses": [
        "6ad3d138-fedc-4db8-ae9b-913e2bd6cf1e"
      ],
      "children": [
        "a6e4f485-c2b8-45cb-816a-a2c027024dc8",
        "a36d702b-fe7a-4f01-ad4f-fd9ab237d542"
      ]
    }
  },
  {
    "id": "d49acc6e-528e-4f19-94a5-bd68666e25d8",
    "data": {
      "gender": "F",
      "first name": "Jhansi",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "f553109d-83ad-48b1-a486-f6f6ef2acaa7"
      ],
      "children": [
        "b8e56ff5-affe-4997-8476-aa9f7a4d0c68",
        "afa6d5b8-2b34-4b66-b4d9-1bd858403494"
      ]
    }
  },
  {
    "id": "b8e56ff5-affe-4997-8476-aa9f7a4d0c68",
    "data": {
      "gender": "M",
      "first name": "Vamsi Raja",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "f553109d-83ad-48b1-a486-f6f6ef2acaa7",
      "mother": "d49acc6e-528e-4f19-94a5-bd68666e25d8",
      "spouses": [
        "e764d904-3f6c-4e8e-9bef-200794106dd3"
      ],
      "children": [
        "58793541-598c-450b-a149-cfb1b62e6455",
        "4d243fb4-d855-4d8e-876b-f428a97821e2"
      ]
    }
  },
  {
    "id": "afa6d5b8-2b34-4b66-b4d9-1bd858403494",
    "data": {
      "gender": "M",
      "first name": "Kiran Raja",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "f553109d-83ad-48b1-a486-f6f6ef2acaa7",
      "mother": "d49acc6e-528e-4f19-94a5-bd68666e25d8",
      "spouses": [
        "e32d3b85-4dab-449d-8df8-9617acecc08f"
      ],
      "children": [
        "f1830f33-56d2-4dda-9d21-0a6a46fd9457",
        "79eb332d-4ca0-4362-9570-14a55f8e764a"
      ]
    }
  },
  {
    "id": "e764d904-3f6c-4e8e-9bef-200794106dd3",
    "data": {
      "gender": "F",
      "first name": "Swarnalatha",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "b8e56ff5-affe-4997-8476-aa9f7a4d0c68"
      ],
      "children": [
        "58793541-598c-450b-a149-cfb1b62e6455",
        "4d243fb4-d855-4d8e-876b-f428a97821e2"
      ],
      "father": "9b9296df-5d78-4c6d-9817-0f22a78a4491",
      "mother": "d3ffb858-98a4-4c5a-8acf-c311a26b19fc"
    }
  },
  {
    "id": "58793541-598c-450b-a149-cfb1b62e6455",
    "data": {
      "gender": "M",
      "first name": "Aakash Raja",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "b8e56ff5-affe-4997-8476-aa9f7a4d0c68",
      "mother": "e764d904-3f6c-4e8e-9bef-200794106dd3"
    }
  },
  {
    "id": "4d243fb4-d855-4d8e-876b-f428a97821e2",
    "data": {
      "gender": "M",
      "first name": "Vikas Raja",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "b8e56ff5-affe-4997-8476-aa9f7a4d0c68",
      "mother": "e764d904-3f6c-4e8e-9bef-200794106dd3"
    }
  },
  {
    "id": "e32d3b85-4dab-449d-8df8-9617acecc08f",
    "data": {
      "gender": "F",
      "first name": "Julie",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "afa6d5b8-2b34-4b66-b4d9-1bd858403494"
      ],
      "children": [
        "f1830f33-56d2-4dda-9d21-0a6a46fd9457",
        "79eb332d-4ca0-4362-9570-14a55f8e764a"
      ],
      "father": "2027b38c-8c2b-4a69-84ec-62b7c553e5be",
      "mother": "8c373b02-a2dd-453f-a28b-73083ff12c2b"
    }
  },
  {
    "id": "f1830f33-56d2-4dda-9d21-0a6a46fd9457",
    "data": {
      "gender": "M",
      "first name": "Pratim Raja",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "e32d3b85-4dab-449d-8df8-9617acecc08f",
      "father": "afa6d5b8-2b34-4b66-b4d9-1bd858403494"
    }
  },
  {
    "id": "79eb332d-4ca0-4362-9570-14a55f8e764a",
    "data": {
      "gender": "F",
      "first name": "Jasmine Raja",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "afa6d5b8-2b34-4b66-b4d9-1bd858403494",
      "mother": "e32d3b85-4dab-449d-8df8-9617acecc08f"
    }
  },
  {
    "id": "cdb217e4-91ae-4170-ab0c-7656504ae783",
    "data": {
      "gender": "M",
      "first name": "Sudarshan Surampudi",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "839713d2-1cff-46c9-a6aa-786e4c672c88"
      ],
      "children": [
        "5456f95b-f2ce-40c7-8ced-85a6ec8fdd1b"
      ]
    }
  },
  {
    "id": "5456f95b-f2ce-40c7-8ced-85a6ec8fdd1b",
    "data": {
      "gender": "M",
      "first name": "Rakesh Surampudi",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "cdb217e4-91ae-4170-ab0c-7656504ae783",
      "mother": "839713d2-1cff-46c9-a6aa-786e4c672c88",
      "spouses": [
        "70409d84-3490-4e0a-b81a-ed8f2ef1a268"
      ],
      "children": [
        "cfdd2630-8a05-4ba6-8723-826335baddb8",
        "ed8ef589-8f9e-4b4b-9330-60fadb726195"
      ]
    }
  },
  {
    "id": "70409d84-3490-4e0a-b81a-ed8f2ef1a268",
    "data": {
      "gender": "F",
      "first name": "Rebecca Surampudi",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "5456f95b-f2ce-40c7-8ced-85a6ec8fdd1b"
      ],
      "children": [
        "cfdd2630-8a05-4ba6-8723-826335baddb8",
        "ed8ef589-8f9e-4b4b-9330-60fadb726195"
      ]
    }
  },
  {
    "id": "cfdd2630-8a05-4ba6-8723-826335baddb8",
    "data": {
      "gender": "M",
      "first name": "Kailash Surampudi",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "70409d84-3490-4e0a-b81a-ed8f2ef1a268",
      "father": "5456f95b-f2ce-40c7-8ced-85a6ec8fdd1b"
    }
  },
  {
    "id": "ed8ef589-8f9e-4b4b-9330-60fadb726195",
    "data": {
      "gender": "M",
      "first name": "Eli Surampudi",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "5456f95b-f2ce-40c7-8ced-85a6ec8fdd1b",
      "mother": "70409d84-3490-4e0a-b81a-ed8f2ef1a268"
    }
  },
  {
    "id": "6ad3d138-fedc-4db8-ae9b-913e2bd6cf1e",
    "data": {
      "gender": "F",
      "first name": "Manga",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "34c2e2a7-6900-4332-83e4-3e6d415e374b"
      ],
      "children": [
        "a6e4f485-c2b8-45cb-816a-a2c027024dc8",
        "a36d702b-fe7a-4f01-ad4f-fd9ab237d542"
      ]
    }
  },
  {
    "id": "a6e4f485-c2b8-45cb-816a-a2c027024dc8",
    "data": {
      "gender": "M",
      "first name": "Vamsi Raja",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "6ad3d138-fedc-4db8-ae9b-913e2bd6cf1e",
      "father": "34c2e2a7-6900-4332-83e4-3e6d415e374b",
      "spouses": [
        "8695d201-0f1c-47ce-b8d0-c55f5d0398ba"
      ],
      "children": [
        "c3bd73b3-bcd3-4e0d-b8f4-b925e2441101",
        "d90d0bef-d1b1-435b-9216-b68ce00db89b"
      ]
    }
  },
  {
    "id": "a36d702b-fe7a-4f01-ad4f-fd9ab237d542",
    "data": {
      "gender": "F",
      "first name": "Soujanya",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "34c2e2a7-6900-4332-83e4-3e6d415e374b",
      "mother": "6ad3d138-fedc-4db8-ae9b-913e2bd6cf1e",
      "spouses": [
        "cb4024c4-6e27-41fb-a913-9552d0859a68"
      ],
      "children": [
        "de0c19d0-a032-41cf-992a-ea494e4f9de7"
      ]
    }
  },
  {
    "id": "8695d201-0f1c-47ce-b8d0-c55f5d0398ba",
    "data": {
      "gender": "F",
      "first name": "Rajitha",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "a6e4f485-c2b8-45cb-816a-a2c027024dc8"
      ],
      "children": [
        "c3bd73b3-bcd3-4e0d-b8f4-b925e2441101",
        "d90d0bef-d1b1-435b-9216-b68ce00db89b"
      ]
    }
  },
  {
    "id": "c3bd73b3-bcd3-4e0d-b8f4-b925e2441101",
    "data": {
      "gender": "M",
      "first name": "Janardhan Raja",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "8695d201-0f1c-47ce-b8d0-c55f5d0398ba",
      "father": "a6e4f485-c2b8-45cb-816a-a2c027024dc8"
    }
  },
  {
    "id": "d90d0bef-d1b1-435b-9216-b68ce00db89b",
    "data": {
      "gender": "M",
      "first name": "Rishi Raja",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "a6e4f485-c2b8-45cb-816a-a2c027024dc8",
      "mother": "8695d201-0f1c-47ce-b8d0-c55f5d0398ba"
    }
  },
  {
    "id": "cb4024c4-6e27-41fb-a913-9552d0859a68",
    "data": {
      "gender": "M",
      "first name": "Kumar",
      "last name": "Muthuvel",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "a36d702b-fe7a-4f01-ad4f-fd9ab237d542"
      ],
      "children": [
        "de0c19d0-a032-41cf-992a-ea494e4f9de7"
      ]
    }
  },
  {
    "id": "de0c19d0-a032-41cf-992a-ea494e4f9de7",
    "data": {
      "gender": "F",
      "first name": "Shilpa Kumar",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "cb4024c4-6e27-41fb-a913-9552d0859a68",
      "mother": "a36d702b-fe7a-4f01-ad4f-fd9ab237d542"
    }
  },
  {
    "id": "027910cd-7a03-40c9-a614-f89aa70ef6af",
    "data": {
      "gender": "F",
      "first name": "Bharati",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "de418782-6537-40fb-8f7d-802142487634"
      ],
      "children": [
        "0adea439-3093-420f-b4c4-92dd1ee08f78",
        "4eb9f27a-61c0-42d9-a232-fb322ddda48b"
      ]
    }
  },
  {
    "id": "0adea439-3093-420f-b4c4-92dd1ee08f78",
    "data": {
      "gender": "F",
      "first name": "Yamini",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "027910cd-7a03-40c9-a614-f89aa70ef6af",
      "father": "de418782-6537-40fb-8f7d-802142487634",
      "spouses": [
        "e25378c0-3784-4c39-8d1a-4b20809969c0"
      ],
      "children": [
        "0686b443-9895-45e5-9acc-278cebc26171",
        "55f6be70-c30a-4e06-ad56-922358b6c5a5"
      ]
    }
  },
  {
    "id": "e25378c0-3784-4c39-8d1a-4b20809969c0",
    "data": {
      "gender": "M",
      "first name": "Nagesgwar",
      "last name": "Koneru",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "0adea439-3093-420f-b4c4-92dd1ee08f78"
      ],
      "children": [
        "0686b443-9895-45e5-9acc-278cebc26171",
        "55f6be70-c30a-4e06-ad56-922358b6c5a5"
      ]
    }
  },
  {
    "id": "0686b443-9895-45e5-9acc-278cebc26171",
    "data": {
      "gender": "F",
      "first name": "Sindoora",
      "last name": "Koneru",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "0adea439-3093-420f-b4c4-92dd1ee08f78",
      "father": "e25378c0-3784-4c39-8d1a-4b20809969c0"
    }
  },
  {
    "id": "55f6be70-c30a-4e06-ad56-922358b6c5a5",
    "data": {
      "gender": "F",
      "first name": "Meghana",
      "last name": "Koneru",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "e25378c0-3784-4c39-8d1a-4b20809969c0",
      "mother": "0adea439-3093-420f-b4c4-92dd1ee08f78"
    }
  },
  {
    "id": "4eb9f27a-61c0-42d9-a232-fb322ddda48b",
    "data": {
      "gender": "M",
      "first name": "Kishore",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "de418782-6537-40fb-8f7d-802142487634",
      "mother": "027910cd-7a03-40c9-a614-f89aa70ef6af"
    }
  },
  {
    "id": "f29d0e02-ba27-49f6-9325-53d59cb13830",
    "data": {
      "gender": "M",
      "first name": "SriRamulu",
      "last name": "Kolli",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "17b5dd1c-c586-4437-86a3-89a813b8e378"
      ],
      "children": [
        "8ea94d41-64ac-40c7-93e9-f77626fbc0c0",
        "0e5f8ba5-f521-4d83-9fbe-00dd4f8fa726"
      ]
    }
  },
  {
    "id": "8ea94d41-64ac-40c7-93e9-f77626fbc0c0",
    "data": {
      "gender": "F",
      "first name": "Shyama ",
      "last name": "Kolli",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "17b5dd1c-c586-4437-86a3-89a813b8e378",
      "father": "f29d0e02-ba27-49f6-9325-53d59cb13830"
    }
  },
  {
    "id": "0e5f8ba5-f521-4d83-9fbe-00dd4f8fa726",
    "data": {
      "gender": "F",
      "first name": "SeethaRatnam",
      "last name": "Kolli",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "f29d0e02-ba27-49f6-9325-53d59cb13830",
      "mother": "17b5dd1c-c586-4437-86a3-89a813b8e378"
    }
  },
  {
    "id": "b93fac1c-dd6a-42b8-893c-30ec0a3bad3f",
    "data": {
      "gender": "M",
      "first name": "Jwala",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "4cd034b3-881c-4474-8d7d-db65e1bd25ce"
      ],
      "children": [
        "b48ba152-e32c-43af-84c0-87140cc9cf0b",
        "fb250762-f0da-4207-9b7c-a486112f6859",
        "8e55515c-eeaa-4247-b58d-e60b5135e6e2",
        "3f79b386-3205-4d2e-a649-22c4fb8320b6"
      ]
    }
  },
  {
    "id": "b48ba152-e32c-43af-84c0-87140cc9cf0b",
    "data": {
      "gender": "F",
      "first name": "Charumathi",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "4cd034b3-881c-4474-8d7d-db65e1bd25ce",
      "father": "b93fac1c-dd6a-42b8-893c-30ec0a3bad3f"
    }
  },
  {
    "id": "fb250762-f0da-4207-9b7c-a486112f6859",
    "data": {
      "gender": "F",
      "first name": "Sukanya",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "b93fac1c-dd6a-42b8-893c-30ec0a3bad3f",
      "mother": "4cd034b3-881c-4474-8d7d-db65e1bd25ce"
    }
  },
  {
    "id": "8e55515c-eeaa-4247-b58d-e60b5135e6e2",
    "data": {
      "gender": "F",
      "first name": "Nalini",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "b93fac1c-dd6a-42b8-893c-30ec0a3bad3f",
      "mother": "4cd034b3-881c-4474-8d7d-db65e1bd25ce"
    }
  },
  {
    "id": "3f79b386-3205-4d2e-a649-22c4fb8320b6",
    "data": {
      "gender": "F",
      "first name": "Aparna",
      "last name": "Vasireddy",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "b93fac1c-dd6a-42b8-893c-30ec0a3bad3f",
      "mother": "4cd034b3-881c-4474-8d7d-db65e1bd25ce"
    }
  },
  {
    "id": "4b80f15b-b5ef-494c-8c67-d7fa228e56e8",
    "data": {
      "gender": "M",
      "first name": "Ramaiah",
      "last name": "Parupalli",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "children": [
        "5107ea7b-2af8-419b-8737-f6376e273087",
        "5433eb86-4d50-4706-a80f-7ae6aab2c190",
        "257bad4c-dbf4-4adb-a449-592e82b16d3f",
        "1f3ffe2e-e2c7-4176-a3ad-be92eaecad62",
        "00795e6e-3999-4d39-8ebc-078d15d6771e"
      ],
      "spouses": [
        "5c60bd36-d559-4030-bf52-126d67c65bbd",
        "11037ba6-9b1a-4b36-b936-e5046de059e4"
      ]
    }
  },
  {
    "id": "5c60bd36-d559-4030-bf52-126d67c65bbd",
    "data": {
      "gender": "F",
      "first name": "Wife of Ramaiah",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "4b80f15b-b5ef-494c-8c67-d7fa228e56e8"
      ],
      "children": [
        "5107ea7b-2af8-419b-8737-f6376e273087",
        "5433eb86-4d50-4706-a80f-7ae6aab2c190"
      ]
    }
  },
  {
    "id": "5433eb86-4d50-4706-a80f-7ae6aab2c190",
    "data": {
      "gender": "F",
      "first name": "Sarojini",
      "last name": "Parupalli",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "4b80f15b-b5ef-494c-8c67-d7fa228e56e8",
      "mother": "5c60bd36-d559-4030-bf52-126d67c65bbd",
      "spouses": [
        "5541502b-8ee8-4b34-a92f-211979628254"
      ],
      "children": [
        "b8408443-bfee-4d97-9d57-a1768ca34ae7",
        "60acbf93-ff71-4f4e-a57d-3d1b3f1b43ee",
        "512ca9fe-6a03-43ce-8297-94ac6117b080"
      ]
    }
  },
  {
    "id": "11037ba6-9b1a-4b36-b936-e5046de059e4",
    "data": {
      "gender": "F",
      "first name": "Basavamma",
      "last name": "Parupalli",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "4b80f15b-b5ef-494c-8c67-d7fa228e56e8"
      ],
      "children": [
        "257bad4c-dbf4-4adb-a449-592e82b16d3f",
        "1f3ffe2e-e2c7-4176-a3ad-be92eaecad62",
        "00795e6e-3999-4d39-8ebc-078d15d6771e"
      ]
    }
  },
  {
    "id": "257bad4c-dbf4-4adb-a449-592e82b16d3f",
    "data": {
      "gender": "M",
      "first name": "Chandu",
      "last name": "Parupalli",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "4b80f15b-b5ef-494c-8c67-d7fa228e56e8",
      "mother": "11037ba6-9b1a-4b36-b936-e5046de059e4"
    }
  },
  {
    "id": "1f3ffe2e-e2c7-4176-a3ad-be92eaecad62",
    "data": {
      "gender": "M",
      "first name": "ChiruKandu",
      "last name": "Parupalli",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "4b80f15b-b5ef-494c-8c67-d7fa228e56e8",
      "mother": "11037ba6-9b1a-4b36-b936-e5046de059e4",
      "spouses": [
        "fdec7bda-fe00-4ad7-9d1b-00f7e77cabc9"
      ],
      "children": [
        "2e44f4bd-5399-4e0c-b6ff-dce5540be02b",
        "608a2aa4-7116-4e44-b107-a6aeb8ea662c"
      ]
    }
  },
  {
    "id": "00795e6e-3999-4d39-8ebc-078d15d6771e",
    "data": {
      "gender": "M",
      "first name": "Gorkey",
      "last name": "Parupalli",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "4b80f15b-b5ef-494c-8c67-d7fa228e56e8",
      "mother": "11037ba6-9b1a-4b36-b936-e5046de059e4"
    }
  },
  {
    "id": "fdec7bda-fe00-4ad7-9d1b-00f7e77cabc9",
    "data": {
      "gender": "F",
      "first name": "Kamalamma",
      "last name": "Parupalli",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "1f3ffe2e-e2c7-4176-a3ad-be92eaecad62"
      ],
      "children": [
        "2e44f4bd-5399-4e0c-b6ff-dce5540be02b",
        "608a2aa4-7116-4e44-b107-a6aeb8ea662c"
      ]
    }
  },
  {
    "id": "2e44f4bd-5399-4e0c-b6ff-dce5540be02b",
    "data": {
      "gender": "F",
      "first name": "Silpa",
      "last name": "Parupalli",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "fdec7bda-fe00-4ad7-9d1b-00f7e77cabc9",
      "father": "1f3ffe2e-e2c7-4176-a3ad-be92eaecad62"
    }
  },
  {
    "id": "608a2aa4-7116-4e44-b107-a6aeb8ea662c",
    "data": {
      "gender": "M",
      "first name": "Sahi",
      "last name": "Parupalli",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "1f3ffe2e-e2c7-4176-a3ad-be92eaecad62",
      "mother": "fdec7bda-fe00-4ad7-9d1b-00f7e77cabc9"
    }
  },
  {
    "id": "5541502b-8ee8-4b34-a92f-211979628254",
    "data": {
      "gender": "M",
      "first name": "Surya",
      "last name": "Sakhamuri",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "5433eb86-4d50-4706-a80f-7ae6aab2c190"
      ],
      "children": [
        "b8408443-bfee-4d97-9d57-a1768ca34ae7",
        "60acbf93-ff71-4f4e-a57d-3d1b3f1b43ee",
        "512ca9fe-6a03-43ce-8297-94ac6117b080"
      ]
    }
  },
  {
    "id": "b8408443-bfee-4d97-9d57-a1768ca34ae7",
    "data": {
      "gender": "M",
      "first name": "Vijay",
      "last name": "Sakhamuri",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "5433eb86-4d50-4706-a80f-7ae6aab2c190",
      "father": "5541502b-8ee8-4b34-a92f-211979628254",
      "spouses": [
        "91623421-6000-4825-adb2-768f6500d9a4"
      ],
      "children": [
        "e259bd94-1c26-4c09-b6a8-8a8cb983a88e",
        "faa3667f-4c42-4850-b059-03d9da449e30"
      ]
    }
  },
  {
    "id": "60acbf93-ff71-4f4e-a57d-3d1b3f1b43ee",
    "data": {
      "gender": "M",
      "first name": "Ravi",
      "last name": "Sakhamuri",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "5541502b-8ee8-4b34-a92f-211979628254",
      "mother": "5433eb86-4d50-4706-a80f-7ae6aab2c190",
      "spouses": [
        "5aa1d2a2-fe9f-4f09-b504-0548649bf966"
      ],
      "children": [
        "65cee292-ed0d-4472-98b4-cd177466513c",
        "b1b35d46-fc9f-496b-ad09-07e3c198412f"
      ]
    }
  },
  {
    "id": "512ca9fe-6a03-43ce-8297-94ac6117b080",
    "data": {
      "gender": "M",
      "first name": "Santosh",
      "last name": "Sakhamuri",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "5541502b-8ee8-4b34-a92f-211979628254",
      "mother": "5433eb86-4d50-4706-a80f-7ae6aab2c190",
      "spouses": [
        "14a52004-b2bc-4506-b996-62066afb4bc5"
      ],
      "children": [
        "4a6036d3-96c3-482c-8776-32894b15a713",
        "e9e93a1f-ae9f-4364-9df2-7300710b6964"
      ]
    }
  },
  {
    "id": "91623421-6000-4825-adb2-768f6500d9a4",
    "data": {
      "gender": "F",
      "first name": "Sujatha",
      "last name": "Sakhamuri",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "b8408443-bfee-4d97-9d57-a1768ca34ae7"
      ],
      "children": [
        "e259bd94-1c26-4c09-b6a8-8a8cb983a88e",
        "faa3667f-4c42-4850-b059-03d9da449e30"
      ]
    }
  },
  {
    "id": "e259bd94-1c26-4c09-b6a8-8a8cb983a88e",
    "data": {
      "gender": "F",
      "first name": "Vijetha",
      "last name": "Sakhamuri",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "91623421-6000-4825-adb2-768f6500d9a4",
      "father": "b8408443-bfee-4d97-9d57-a1768ca34ae7"
    }
  },
  {
    "id": "faa3667f-4c42-4850-b059-03d9da449e30",
    "data": {
      "gender": "M",
      "first name": "Sri",
      "last name": "Sakhamuri",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "b8408443-bfee-4d97-9d57-a1768ca34ae7",
      "mother": "91623421-6000-4825-adb2-768f6500d9a4"
    }
  },
  {
    "id": "5aa1d2a2-fe9f-4f09-b504-0548649bf966",
    "data": {
      "gender": "F",
      "first name": "Bhavani",
      "last name": "Sakhamuri",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "60acbf93-ff71-4f4e-a57d-3d1b3f1b43ee"
      ],
      "children": [
        "65cee292-ed0d-4472-98b4-cd177466513c",
        "b1b35d46-fc9f-496b-ad09-07e3c198412f"
      ]
    }
  },
  {
    "id": "65cee292-ed0d-4472-98b4-cd177466513c",
    "data": {
      "gender": "M",
      "first name": "Prashanth",
      "last name": "Sakhamuri",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "5aa1d2a2-fe9f-4f09-b504-0548649bf966",
      "father": "60acbf93-ff71-4f4e-a57d-3d1b3f1b43ee"
    }
  },
  {
    "id": "b1b35d46-fc9f-496b-ad09-07e3c198412f",
    "data": {
      "gender": "F",
      "first name": "Sarojini",
      "last name": "Sakhamuri",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "60acbf93-ff71-4f4e-a57d-3d1b3f1b43ee",
      "mother": "5aa1d2a2-fe9f-4f09-b504-0548649bf966"
    }
  },
  {
    "id": "14a52004-b2bc-4506-b996-62066afb4bc5",
    "data": {
      "gender": "F",
      "first name": "Vijaya",
      "last name": "Sakhamuri",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "512ca9fe-6a03-43ce-8297-94ac6117b080"
      ],
      "children": [
        "4a6036d3-96c3-482c-8776-32894b15a713",
        "e9e93a1f-ae9f-4364-9df2-7300710b6964"
      ]
    }
  },
  {
    "id": "4a6036d3-96c3-482c-8776-32894b15a713",
    "data": {
      "gender": "M",
      "first name": "Praveen",
      "last name": "Sakhamuri",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "14a52004-b2bc-4506-b996-62066afb4bc5",
      "father": "512ca9fe-6a03-43ce-8297-94ac6117b080"
    }
  },
  {
    "id": "e9e93a1f-ae9f-4364-9df2-7300710b6964",
    "data": {
      "gender": "F",
      "first name": "Sindhura",
      "last name": "Sakhamuri",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "512ca9fe-6a03-43ce-8297-94ac6117b080",
      "mother": "14a52004-b2bc-4506-b996-62066afb4bc5"
    }
  },
  {
    "id": "9b9296df-5d78-4c6d-9817-0f22a78a4491",
    "data": {
      "gender": "M",
      "first name": "Mohan Rao",
      "last name": "Jaliparthi",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "children": [
        "e764d904-3f6c-4e8e-9bef-200794106dd3"
      ],
      "spouses": [
        "d3ffb858-98a4-4c5a-8acf-c311a26b19fc"
      ]
    }
  },
  {
    "id": "d3ffb858-98a4-4c5a-8acf-c311a26b19fc",
    "data": {
      "gender": "F",
      "first name": "Vanaja",
      "last name": "Jaliparthi",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "9b9296df-5d78-4c6d-9817-0f22a78a4491"
      ],
      "children": [
        "e764d904-3f6c-4e8e-9bef-200794106dd3"
      ]
    }
  },
  {
    "id": "2027b38c-8c2b-4a69-84ec-62b7c553e5be",
    "data": {
      "gender": "M",
      "first name": "Father of Julie",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "children": [
        "e32d3b85-4dab-449d-8df8-9617acecc08f",
        "b0b56f14-de46-4245-931c-c6511a663f2a",
        "75b6100d-8349-4d16-b30c-35761853cb43"
      ],
      "spouses": [
        "8c373b02-a2dd-453f-a28b-73083ff12c2b"
      ]
    }
  },
  {
    "id": "8c373b02-a2dd-453f-a28b-73083ff12c2b",
    "data": {
      "gender": "F",
      "first name": "Mother of Julie",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "2027b38c-8c2b-4a69-84ec-62b7c553e5be"
      ],
      "children": [
        "e32d3b85-4dab-449d-8df8-9617acecc08f",
        "b0b56f14-de46-4245-931c-c6511a663f2a",
        "75b6100d-8349-4d16-b30c-35761853cb43"
      ]
    }
  },
  {
    "id": "b0b56f14-de46-4245-931c-c6511a663f2a",
    "data": {
      "gender": "M",
      "first name": "Priti Malfait",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "2027b38c-8c2b-4a69-84ec-62b7c553e5be",
      "mother": "8c373b02-a2dd-453f-a28b-73083ff12c2b",
      "spouses": [
        "58a1776f-5e52-4bec-a2f3-ff2ecc1b5e7c"
      ],
      "children": [
        "05298b38-a4e8-4b47-bce3-a80e0135a5c6",
        "3445e819-1662-45cd-9774-648842447272"
      ]
    }
  },
  {
    "id": "75b6100d-8349-4d16-b30c-35761853cb43",
    "data": {
      "gender": "M",
      "first name": "Prabhat Sinha",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "8c373b02-a2dd-453f-a28b-73083ff12c2b",
      "father": "2027b38c-8c2b-4a69-84ec-62b7c553e5be",
      "spouses": [
        "66fbf83f-fb37-41c2-9616-8461a664e6ec"
      ],
      "children": [
        "97264d14-4778-41b6-a2a8-e9d3650cbc41",
        "6fd441e3-cf78-4c98-bd7f-f951d2995267"
      ]
    }
  },
  {
    "id": "58a1776f-5e52-4bec-a2f3-ff2ecc1b5e7c",
    "data": {
      "gender": "F",
      "first name": "Jason Malfait",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "b0b56f14-de46-4245-931c-c6511a663f2a"
      ],
      "children": [
        "05298b38-a4e8-4b47-bce3-a80e0135a5c6",
        "3445e819-1662-45cd-9774-648842447272"
      ]
    }
  },
  {
    "id": "05298b38-a4e8-4b47-bce3-a80e0135a5c6",
    "data": {
      "gender": "F",
      "first name": "Priya Malfait",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "58a1776f-5e52-4bec-a2f3-ff2ecc1b5e7c",
      "father": "b0b56f14-de46-4245-931c-c6511a663f2a"
    }
  },
  {
    "id": "3445e819-1662-45cd-9774-648842447272",
    "data": {
      "gender": "F",
      "first name": "Jaya Malfait",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "b0b56f14-de46-4245-931c-c6511a663f2a",
      "mother": "58a1776f-5e52-4bec-a2f3-ff2ecc1b5e7c"
    }
  },
  {
    "id": "66fbf83f-fb37-41c2-9616-8461a664e6ec",
    "data": {
      "gender": "F",
      "first name": "Amrutha Sinha",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "spouses": [
        "75b6100d-8349-4d16-b30c-35761853cb43"
      ],
      "children": [
        "97264d14-4778-41b6-a2a8-e9d3650cbc41",
        "6fd441e3-cf78-4c98-bd7f-f951d2995267"
      ]
    }
  },
  {
    "id": "97264d14-4778-41b6-a2a8-e9d3650cbc41",
    "data": {
      "gender": "M",
      "first name": "Ayan Sinha",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "mother": "66fbf83f-fb37-41c2-9616-8461a664e6ec",
      "father": "75b6100d-8349-4d16-b30c-35761853cb43"
    }
  },
  {
    "id": "6fd441e3-cf78-4c98-bd7f-f951d2995267",
    "data": {
      "gender": "F",
      "first name": "Arya Sinha",
      "last name": "",
      "birthday": "",
      "avatar": "https://static8.depositphotos.com/1009634/988/v/950/depositphotos_9883921-stock-illustration-no-user-profile-picture.jpg"
    },
    "rels": {
      "father": "75b6100d-8349-4d16-b30c-35761853cb43",
      "mother": "66fbf83f-fb37-41c2-9616-8461a664e6ec"
    }
  }
]
    }
  
  }

  render() {
    return <div className="f3" id="FamilyChart" ref={this.cont}></div>;
  }
}