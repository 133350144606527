import React, { useEffect } from 'react';
import FamilyChart from "./familychart";



const App = () => {
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  return (
    <div>
     <FamilyChart />
    </div>
  );
};

export default App;
